<template>
	<form @submit.prevent="authenticate()">
		<!-- <h4 class="mb-3 f-w-400 text-center">Signin</h4> -->
		<div class="form-group mb-3">
			<label class="control-label" for="Email">Email address</label>
			<input
				v-model="form.email"
				type="text"
				class="form-control"
				id="Email"
				placeholder=""
				required
			/>
		</div>
		<div class="form-group mb-4">
			<label class="control-label" for="Password">Password</label>
			<div class="input-group">
				<input
					v-model="form.password"
					:type="passwordFieldType"
					class="form-control"
					id="Password"
					placeholder=""
					required
				/>
				<div class="input-group-append">
					<span
						class="input-group-text"
						style="padding: 0.625rem 0.5rem"
						@click="togglePasswordVisibility"
					>
						<i :class="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
					</span>
				</div>
			</div>
		</div>

		<!-- <div class="form-check text-start mb-4 mt-2">
			<input type="checkbox" class="form-check-input" id="customCheck1" />
			<label class="form-check-label" for="customCheck1">Remember Me</label>
		</div> -->
		<div class="align-items-center text-center">
			<LoadingButton class="btn btn-block btn-info mb-4" style="width: 100%"
				>Sign In</LoadingButton
			>
		</div>
		<p class="mb-2 text-muted text-center">
			Forgot password?
			<a href="/forgot-password" class="f-w-400 text-primary">Reset</a>
		</p>
	</form>
</template>
<script>
import LoadingButton from "@/components/LoadingButton.vue";
import { mapActions, mapGetters } from "vuex";
import Bowser from "bowser";
export default {
	components: {
		LoadingButton,
	},

	data() {
		return {
			form: {
				email: null,
				password: null,
				device_name: Bowser.getParser(
					window.navigator.userAgent
				).getBrowserName(),
			},
			passwordVisible: false,
		};
	},

	computed: {
		...mapGetters({
			isAuthenticated: "auth/authenticated",
		}),
		passwordFieldType() {
			return this.passwordVisible ? "text" : "password";
		},
	},

	methods: {
		...mapActions({
			login: "auth/Login",
		}),

		togglePasswordVisibility() {
			this.passwordVisible = !this.passwordVisible;
		},

		authenticate() {
			this.login(this.form).then((response) => {
				if (response === "success" && this.isAuthenticated) {
					this.$router
						.push("/dashboard")
						.then(() => {})
						// .then(() => location.reload())
						.catch(() => {});
				}
			});
		},
	},
};
</script>
<style scoped>
.form-control {
	padding: 5px 10px;
}
.input-group-append {
	cursor: pointer;
}

.input-group-text {
	/* background: transparent; */
	border: none;
}

.fa-eye,
.fa-eye-slash {
	font-size: 1.2em;
}
</style>
