import axios from "axios";
import store from "@/store";
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

const Request = axios.create({
  // baseURL: process.env.VUE_APP_MODE === 'local' ? process.env.VUE_APP_LOCAL_URL : process.env.VUE_APP_PRODUCTION_URL,
  baseURL: 'https://pfras.inecnigeria.org/api',
  timeout: 5000,
});

Request.interceptors.request.use(
  (config) => {
    let data = ls.get("pfras");
    if (data) {
      const token = JSON.parse(data).auth.token;
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Request.interceptors.request.use(
  (config) => {
    commitData(true, null);
    NProgress.start()
    
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Request.interceptors.response.use(
  (resp) => {
    NProgress.done();
    commitData(false, null);

    let response = resp.data;
    if (response && response.status_code == 201 || response.status_code == 206) {
      store.commit("shared/setSuccessResponse", response.status_message);
    }
    
    if (response) {
      return response;
    }
  },
  (error) => {
      NProgress.done()

      if (error && error.response && error.response.status >= 400 && error.response.status < 500) {
        let err = null;

        if (error.response.status === 401 && error.response.data.message === 'Unauthenticated.') {
          err = error.response.data.message;
          store.commit("auth/logout");
        }else if(error.response.status === 429 || error.response.status === 403){
          err = error.response.data.message ?? error.response.data.data.message;
        }else{
          err = error.response.data && error.response.data.errors ? error.response.data.errors : error.response.data.data.message;
        }

        commitData(false, err);
      }else{
        
        commitData(
          false,
          "Something went wrong, we're unable to process your request, please try again!"
        );
      }

    return error;
  }
);

const commitData = function (type, msg) {
  store.commit("shared/setLoading", type);
  store.commit("shared/setErrorResponse", msg);
};

export default Request;
